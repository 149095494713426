<template>
  <div class="calendar">
    <v-date-picker
      is-inline
      mode="single"
      locale="ko-KR"
      :pane-width="480"
      :theme-styles="themeStyles"
      :formats="formats"
      :min-date="new Date(moment().add(1, 'days').valueOf())"
      v-model="eventDate"
      @input="val => saveEventDate(val)"
    >
    </v-date-picker>

    <footer-box
      submitText="확인"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Icon from '@/components/common/Icon'

export default {
  components: {
    PageHeader,
    FooterBox,
    Icon,
  },
  data() {
    return {
      themeStyles: {
        dayContent: {
          fontSize: '2rem',
          lineHeight: '8rem',
          width: '6rem',
          height: '6rem',
        },
        dayBackground: {
          width: '6rem',
          height: '6rem',
        }
      },
      formats: {
        title: 'YYYY년 MM월',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['YYYY.MM.DD'],
        dayPopover: 'L',
        data: ['YYYY.MM.DD'],
      },
      eventDateModel: null,
      eventDate: null,
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return this.eventDate !== null
      },
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.eventDate = sessionStorage.getItem('eventDate') || null
    })
  },
  methods: {
    saveEventDate(val) {
      const eventDate = this.moment(new Date(val).getTime()).format('YYYY-MM-DD')
      sessionStorage.setItem('eventDate', eventDate)
    },
    nextStep() {
      this.postOffer = { playDate: null }
      this.$router.back()
    }
  }
}
</script>

<style lang="scss">
#page .c-pane-container {
  border: 0 !important;
  background-color: #fff !important;

  .c-header > .c-title-layout.align-center > div > div > div {
    font-size: 2.4rem !important;
    padding: 2rem;
  }

  .c-weekdays > div {
    font-size: 2rem;
  }

  .c-weeks .c-day .c-day-backgrounds.c-day-layer > div > div {
    width: 4rem !important;
    height: 4rem !important;
  }
}
</style>
